import { useCallback, useContext, useEffect, useState } from "react";
import ScanModal from "../modal/ScanModal";
import CartModal from "../modal/CartModal"; // Nieuwe modal voor de winkelwagen
import { walletUrl } from "../../helpers/urls";
import axios from "axios";
import ProductPreview from "./ProductPreview";
import Loader from "../loader/Loader";
import useCards from "../../api/cards";
import { CartContext } from "../../context/CartContext";
import "./Kassa.css";

export default function Kassa() {
  const [status, setStatus] = useState("order");
  const [data, setData] = useState({});
  const [loadingData, setLoadingData] = useState(true);
  const [currentCategory, setCurrentCategory] = useState("");
  const [cardnr, setCardnr] = useState("");
  const [error, setError] = useState("");
  const [showCart, setShowCart] = useState(false); // State voor de cart modal

  const cardsAPI = useCards();
  const {
    cart,
    cartTotal,
    addToCart,
    removeFromCart,
    increaseQuantity,
    decreaseQuantity,
    clearCart,
  } = useContext(CartContext);

  const handleCloseModal = useCallback(() => {
    setStatus("order");
  }, []);

  const handleConfirmCart = useCallback(() => {
    setStatus("scan");
    setShowCart(false); // Sluit de cart modal bij bevestiging
  }, []);

  const handleScan = useCallback(({ cardnr }) => {
    setCardnr(cardnr);
    setStatus("scanned");
    console.log("Kaart gescand!");
  }, []);

  const changeCategory = useCallback((category) => {
    setCurrentCategory(category);
  });

  useEffect(() => {
    const fetchProducts = async () => {
      if (status === "order") {
        const response = await axios.get(`${walletUrl}/1/products`);
        const categories = Object.keys(response.data);
        console.log(response.data);
        setData({ categories, products: response.data });
        setCurrentCategory(categories[0]);
        setLoadingData(false);
      }
    };

    fetchProducts();
  }, [status]);

  useEffect(() => {
    const confirmOrder = async () => {
      if (status === "scanned" && cardnr) {
        setStatus("loading");
        console.log("sending order to backend");
        const response = await cardsAPI.createTransaction({
          amount: -cartTotal,
          cardnr,
          products: cart,
        });

        console.log(response);
        if (!response.msg) {
          clearCart();
          setCardnr(null);
          setStatus("success");
        } else {
          console.log(response.msg);
          setError(response.msg);
          setStatus("error");
        }
      }
    };
    confirmOrder();
  }, [status, cardnr]);

  return (
    <>
      <div className="container mt-4">
        <ScanModal
          status={status}
          error={error}
          onClose={handleCloseModal}
          onSuccess={handleScan}
        />

        <CartModal
          isOpen={showCart}
          onClose={() => setShowCart(false)}
          products={cart}
          onConfirm={handleConfirmCart}
        />

        <div className="row">
          <div className="col-12 mb-3">
            <div className="btn-group d-flex overflow-x-auto">
              {data?.categories?.map((category) => (
                <button
                  key={category}
                  type="button"
                  onClick={() => changeCategory(category)}
                  className={`btn ${
                    currentCategory === category ? "btn-primary" : "btn-secondary"
                  } my-1`}
                  style={{ flexGrow: 1, flexBasis: "calc(50% - 10px)" }}
                >
                  {category}
                </button>
              ))}
            </div>
          </div>

          {/* Products Section */}
          <div className="col-12 col-md-8 p-2">
            <div className="row">
              {loadingData ? (
                <Loader />
              ) : (
                <>
                  {data?.products[currentCategory]?.map((product) => (
                    <ProductPreview key={product.productid} product={product} />
                  ))}
                </>
              )}
            </div>
          </div>

          {/* Cart Button */}
          <div className="col-12 text-end">
            <button className="btn btn-primary w-100" onClick={() => setShowCart(true)}>
              Winkelwagen ({cart.length})
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
