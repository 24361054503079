import { useContext } from "react";
import CartItem from "./CartItem";
import { CartContext } from "../../context/CartContext";

export default function CartList({ onConfirm }) {
  const { cartTotal, cart } = useContext(CartContext);

  return (
    <div className="col-12 col-md-4 p-2 relative">
      <div className="overflow-y-auto" style={{ maxHeight: "69vh" }}>
        {cart.length > 0 ? (
          cart.map((product) => (
            <CartItem key={product.productid} product={product} />
          ))
        ) : (
          <p className="text-center text-muted">Je winkelwagen is leeg.</p>
        )}
      </div>
      
      <div className="mt-4 position-relative">
        <div className="d-flex justify-content-between">
          <span>Totaal:</span>
          <strong>€{cartTotal?.toFixed(2) || "0.00"}</strong>
        </div>
        <button
          disabled={cart.length === 0}
          className="btn btn-primary w-100 confirm mt-2"
          onClick={() => onConfirm("scan")}
        >
          Bevestig
        </button>
      </div>
    </div>
  );
}
