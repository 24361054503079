import React from "react";
import "./CartModal.css"; // Voeg CSS toe voor styling
import CartList from "../kassa/CartList";

export default function CartModal({ isOpen, onClose, products, onConfirm }) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay overflow-auto" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>Winkelwagen</h2>
        <CartList products={products} onConfirm={onConfirm} />
      </div>
    </div>
  );
}
